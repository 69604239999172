// Generated by purs version 0.13.8
"use strict";
var Control_Alt = require("../Control.Alt/index.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Category = require("../Control.Category/index.js");
var Control_Lazy = require("../Control.Lazy/index.js");
var Control_Monad_State_Class = require("../Control.Monad.State.Class/index.js");
var Control_MonadZero = require("../Control.MonadZero/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Boolean = require("../Data.Boolean/index.js");
var Data_Char = require("../Data.Char/index.js");
var Data_Char_Unicode = require("../Data.Char.Unicode/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Foldable = require("../Data.Foldable/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Int = require("../Data.Int/index.js");
var Data_List = require("../Data.List/index.js");
var Data_List_Types = require("../Data.List.Types/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Ordering = require("../Data.Ordering/index.js");
var Data_Ring = require("../Data.Ring/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_String_Common = require("../Data.String.Common/index.js");
var Data_Unfoldable = require("../Data.Unfoldable/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var $$Math = require("../Math/index.js");
var Text_Parsing_Parser = require("../Text.Parsing.Parser/index.js");
var Text_Parsing_Parser_Combinators = require("../Text.Parsing.Parser.Combinators/index.js");
var Text_Parsing_Parser_String = require("../Text.Parsing.Parser.String/index.js");
var LanguageDef = function (x) {
    return x;
};
var upper = function (dictMonad) {
    return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Text_Parsing_Parser_String.satisfy(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(Data_Char_Unicode.isUpper))("uppercase letter");
};
var unGenLanguageDef = function (v) {
    return v;
};
var token = function (dictMonad) {
    return function (tokpos) {
        return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Control_Monad_State_Class.gets(Text_Parsing_Parser.monadStateParserT(dictMonad))(function (v) {
            return v.value0;
        }))(function (input) {
            var v = Data_List.uncons(input);
            if (v instanceof Data_Maybe.Nothing) {
                return Text_Parsing_Parser.fail(dictMonad)("Unexpected EOF");
            };
            if (v instanceof Data_Maybe.Just) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Text_Parsing_Parser.bindParserT(dictMonad))(Control_Monad_State_Class.modify_(Text_Parsing_Parser.monadStateParserT(dictMonad))(function (v1) {
                    return new Text_Parsing_Parser.ParseState(v.value0.tail, tokpos(v.value0.head), true);
                }))(function () {
                    return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(v.value0.head);
                });
            };
            throw new Error("Failed pattern match at Text.Parsing.Parser.Token (line 53, column 3 - line 58, column 16): " + [ v.constructor.name ]);
        });
    };
};
var when = function (dictMonad) {
    return function (tokpos) {
        return function (f) {
            return Text_Parsing_Parser_Combinators.tryRethrow(dictMonad)(Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(token(dictMonad)(tokpos))(function (a) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Text_Parsing_Parser.bindParserT(dictMonad))(Control_MonadZero.guard(Text_Parsing_Parser.monadZeroParserT(dictMonad))(f(a)))(function () {
                    return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(a);
                });
            }));
        };
    };
};
var theReservedNames = function (dictMonad) {
    return function (v) {
        if (v.caseSensitive) {
            return Data_Array.sort(Data_Ord.ordString)(v.reservedNames);
        };
        if (Data_Boolean.otherwise) {
            return Data_Array.sort(Data_Ord.ordString)(Data_Functor.map(Data_Functor.functorArray)(Data_String_Common.toLower)(v.reservedNames));
        };
        throw new Error("Failed pattern match at Text.Parsing.Parser.Token (line 722, column 1 - line 722, column 82): " + [ v.constructor.name ]);
    };
};
var space = function (dictMonad) {
    return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Text_Parsing_Parser_String.satisfy(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(Data_Char_Unicode.isSpace))("space");
};
var simpleSpace = function (dictMonad) {
    return Text_Parsing_Parser_Combinators.skipMany1(dictMonad)(Text_Parsing_Parser_String.satisfy(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(Data_Char_Unicode.isSpace));
};
var oneLineComment = function (dictMonad) {
    return function (v) {
        return Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(Text_Parsing_Parser_Combinators["try"](dictMonad)(Text_Parsing_Parser_String.string(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(v.commentLine)))(Text_Parsing_Parser_Combinators.skipMany(dictMonad)(Text_Parsing_Parser_String.satisfy(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(function (v1) {
            return v1 !== "\x0a";
        })));
    };
};
var octDigit = function (dictMonad) {
    return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Text_Parsing_Parser_String.satisfy(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(Data_Char_Unicode.isOctDigit))("oct digit");
};
var match = function (dictMonad) {
    return function (dictEq) {
        return function (tokpos) {
            return function (tok) {
                return when(dictMonad)(tokpos)(function (v) {
                    return Data_Eq.eq(dictEq)(v)(tok);
                });
            };
        };
    };
};
var letter = function (dictMonad) {
    return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Text_Parsing_Parser_String.satisfy(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(Data_Char_Unicode.isAlpha))("letter");
};
var isReserved = function ($copy_names) {
    return function ($copy_name) {
        var $tco_var_names = $copy_names;
        var $tco_done = false;
        var $tco_result;
        function $tco_loop(names, name) {
            var v = Data_Array.uncons(names);
            if (v instanceof Data_Maybe.Nothing) {
                $tco_done = true;
                return false;
            };
            if (v instanceof Data_Maybe.Just) {
                var v1 = Data_Ord.compare(Data_Ord.ordString)(v.value0.head)(name);
                if (v1 instanceof Data_Ordering.LT) {
                    $tco_var_names = v.value0.tail;
                    $copy_name = name;
                    return;
                };
                if (v1 instanceof Data_Ordering.EQ) {
                    $tco_done = true;
                    return true;
                };
                if (v1 instanceof Data_Ordering.GT) {
                    $tco_done = true;
                    return false;
                };
                throw new Error("Failed pattern match at Text.Parsing.Parser.Token (line 717, column 39 - line 720, column 53): " + [ v1.constructor.name ]);
            };
            throw new Error("Failed pattern match at Text.Parsing.Parser.Token (line 715, column 5 - line 720, column 53): " + [ v.constructor.name ]);
        };
        while (!$tco_done) {
            $tco_result = $tco_loop($tco_var_names, $copy_name);
        };
        return $tco_result;
    };
};
var isReservedName = function (dictMonad) {
    return function (v) {
        return function (name) {
            var caseName = (function () {
                if (v.caseSensitive) {
                    return name;
                };
                if (Data_Boolean.otherwise) {
                    return Data_String_Common.toLower(name);
                };
                throw new Error("Failed pattern match at Text.Parsing.Parser.Token (line 710, column 5 - line 711, column 57): " + [  ]);
            })();
            return isReserved(theReservedNames(dictMonad)(v))(caseName);
        };
    };
};
var inCommentSingle = function (dictMonad) {
    return function (v) {
        var startEnd = Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_String_CodeUnits.toCharArray(v.commentEnd))(Data_String_CodeUnits.toCharArray(v.commentStart));
        return Control_Lazy.fix(Text_Parsing_Parser.lazyParserT)(function (p) {
            return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Data_Functor["void"](Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(Text_Parsing_Parser_Combinators["try"](dictMonad)(Text_Parsing_Parser_String.string(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(v.commentEnd))))(Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(Text_Parsing_Parser_Combinators.skipMany1(dictMonad)(Text_Parsing_Parser_String.noneOf(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(startEnd)))(p)))(Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(Text_Parsing_Parser_String.oneOf(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(startEnd))(p)))("end of comment");
        });
    };
};
var multiLineComment = function (dictMonad) {
    return function (v) {
        return Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(Text_Parsing_Parser_Combinators["try"](dictMonad)(Text_Parsing_Parser_String.string(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(v.commentStart)))(inComment(dictMonad)(v));
    };
};
var inCommentMulti = function (dictMonad) {
    return function (v) {
        var startEnd = Data_Semigroup.append(Data_Semigroup.semigroupArray)(Data_String_CodeUnits.toCharArray(v.commentEnd))(Data_String_CodeUnits.toCharArray(v.commentStart));
        return Control_Lazy.fix(Text_Parsing_Parser.lazyParserT)(function (p) {
            return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Data_Functor["void"](Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(Text_Parsing_Parser_Combinators["try"](dictMonad)(Text_Parsing_Parser_String.string(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(v.commentEnd))))(Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(multiLineComment(dictMonad)(v))(p)))(Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(Text_Parsing_Parser_Combinators.skipMany1(dictMonad)(Text_Parsing_Parser_String.noneOf(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(startEnd)))(p)))(Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(Text_Parsing_Parser_String.oneOf(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(startEnd))(p)))("end of comment");
        });
    };
};
var inComment = function (dictMonad) {
    return function (v) {
        if (v.nestedComments) {
            return inCommentMulti(dictMonad)(v);
        };
        return inCommentSingle(dictMonad)(v);
    };
};
var whiteSpace$prime = function (dictMonad) {
    return function (v) {
        if (Data_String_Common["null"](v.commentLine) && Data_String_Common["null"](v.commentStart)) {
            return Text_Parsing_Parser_Combinators.skipMany(dictMonad)(Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(simpleSpace(dictMonad))(""));
        };
        if (Data_String_Common["null"](v.commentLine)) {
            return Text_Parsing_Parser_Combinators.skipMany(dictMonad)(Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(simpleSpace(dictMonad))(multiLineComment(dictMonad)(v)))(""));
        };
        if (Data_String_Common["null"](v.commentStart)) {
            return Text_Parsing_Parser_Combinators.skipMany(dictMonad)(Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(simpleSpace(dictMonad))(oneLineComment(dictMonad)(v)))(""));
        };
        if (Data_Boolean.otherwise) {
            return Text_Parsing_Parser_Combinators.skipMany(dictMonad)(Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(simpleSpace(dictMonad))(oneLineComment(dictMonad)(v)))(multiLineComment(dictMonad)(v)))(""));
        };
        throw new Error("Failed pattern match at Text.Parsing.Parser.Token (line 731, column 1 - line 731, column 86): " + [ v.constructor.name ]);
    };
};
var hexDigit = function (dictMonad) {
    return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Text_Parsing_Parser_String.satisfy(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(Data_Char_Unicode.isHexDigit))("hex digit");
};
var digit = function (dictMonad) {
    return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Text_Parsing_Parser_String.satisfy(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(Data_Char_Unicode.isDigit))("digit");
};
var makeTokenParser = function (dictMonad) {
    return function (v) {
        var stringLetter = Text_Parsing_Parser_String.satisfy(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(function (c) {
            return c !== "\"" && (c !== "\\" && c > "\x1a");
        });
        var sign = function (dictRing) {
            return Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Data_Functor.voidLeft(Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)("-"))(Data_Ring.negate(dictRing)))(Data_Functor.voidLeft(Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)("+"))(Control_Category.identity(Control_Category.categoryFn))))(Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(Control_Category.identity(Control_Category.categoryFn)));
        };
        var oper = (function () {
            var go = Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(v.opStart)(function (c) {
                return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Data_Array.many(Text_Parsing_Parser.alternativeParserT(dictMonad))(Text_Parsing_Parser.lazyParserT)(v.opLetter))(function (cs) {
                    return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(Data_String_CodeUnits.singleton(c) + Data_String_CodeUnits.fromCharArray(cs));
                });
            });
            return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(go)("operator");
        })();
        var number = function (base) {
            return function (baseDigit) {
                var folder = function (v1) {
                    return function (v2) {
                        if (v1 instanceof Data_Maybe.Nothing) {
                            return Data_Maybe.Nothing.value;
                        };
                        if (v1 instanceof Data_Maybe.Just) {
                            return Data_Functor.map(Data_Maybe.functorMaybe)(function (v3) {
                                return (base * v1.value0 | 0) + v3 | 0;
                            })(Data_Char_Unicode.digitToInt(v2));
                        };
                        throw new Error("Failed pattern match at Text.Parsing.Parser.Token (line 600, column 9 - line 600, column 49): " + [ v1.constructor.name, v2.constructor.name ]);
                    };
                };
                return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Data_Array.some(Text_Parsing_Parser.alternativeParserT(dictMonad))(Text_Parsing_Parser.lazyParserT)(baseDigit))(function (digits) {
                    return Data_Maybe.maybe(Text_Parsing_Parser.fail(dictMonad)("not digits"))(Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad)))(Data_Foldable.foldl(Data_Foldable.foldableArray)(folder)(new Data_Maybe.Just(0))(digits));
                });
            };
        };
        var octal = Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(Text_Parsing_Parser_String.oneOf(Text_Parsing_Parser_String.stringLikeString)(dictMonad)([ "o", "O" ]))(number(8)(octDigit(dictMonad)));
        var lexeme = function (p) {
            return Control_Apply.applyFirst(Text_Parsing_Parser.applyParserT(dictMonad))(p)(whiteSpace$prime(dictMonad)(v));
        };
        var reservedOp = function (name) {
            var go = Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Text_Parsing_Parser_String.string(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(name))(function () {
                return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Text_Parsing_Parser_Combinators.notFollowedBy(dictMonad)(v.opLetter))("end of " + name);
            });
            return lexeme(Text_Parsing_Parser_Combinators["try"](dictMonad)(go));
        };
        var symbol = function (name) {
            return Data_Functor.voidLeft(Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(lexeme(Text_Parsing_Parser_String.string(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(name)))(name);
        };
        var parens = function (p) {
            return Text_Parsing_Parser_Combinators.between(dictMonad)(symbol("("))(symbol(")"))(p);
        };
        var semi = symbol(";");
        var semiSep = function (p) {
            return Text_Parsing_Parser_Combinators.sepBy(dictMonad)(p)(semi);
        };
        var semiSep1 = function (p) {
            return Text_Parsing_Parser_Combinators.sepBy1(dictMonad)(p)(semi);
        };
        var isReservedOp = function (name) {
            return isReserved(Data_Array.sort(Data_Ord.ordString)(v.reservedOpNames))(name);
        };
        var operator = (function () {
            var go = Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(oper)(function (name) {
                var $82 = isReservedOp(name);
                if ($82) {
                    return Text_Parsing_Parser.fail(dictMonad)("reserved operator " + name);
                };
                return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(name);
            });
            return lexeme(Text_Parsing_Parser_Combinators["try"](dictMonad)(go));
        })();
        var ident = (function () {
            var go = Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(v.identStart)(function (c) {
                return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Data_Array.many(Text_Parsing_Parser.alternativeParserT(dictMonad))(Text_Parsing_Parser.lazyParserT)(v.identLetter))(function (cs) {
                    return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(Data_String_CodeUnits.singleton(c) + Data_String_CodeUnits.fromCharArray(cs));
                });
            });
            return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(go)("identifier");
        })();
        var identifier = (function () {
            var go = Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(ident)(function (name) {
                var $83 = isReservedName(dictMonad)(v)(name);
                if ($83) {
                    return Text_Parsing_Parser.fail(dictMonad)("reserved word " + Data_Show.show(Data_Show.showString)(name));
                };
                return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(name);
            });
            return lexeme(Text_Parsing_Parser_Combinators["try"](dictMonad)(go));
        })();
        var hexadecimal = Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(Text_Parsing_Parser_String.oneOf(Text_Parsing_Parser_String.stringLikeString)(dictMonad)([ "x", "X" ]))(number(16)(hexDigit(dictMonad)));
        var fraction = (function () {
            var op = function (v1) {
                return function (v2) {
                    if (v2 instanceof Data_Maybe.Nothing) {
                        return Data_Maybe.Nothing.value;
                    };
                    if (v2 instanceof Data_Maybe.Just) {
                        return Control_Bind.bind(Data_Maybe.bindMaybe)(Data_Char_Unicode.digitToInt(v1))(function (int$prime) {
                            return Control_Applicative.pure(Data_Maybe.applicativeMaybe)((v2.value0 + Data_Int.toNumber(int$prime)) / 10.0);
                        });
                    };
                    throw new Error("Failed pattern match at Text.Parsing.Parser.Token (line 550, column 9 - line 550, column 51): " + [ v1.constructor.name, v2.constructor.name ]);
                };
            };
            return Text_Parsing_Parser_Combinators.asErrorMessage(dictMonad)("fraction")(Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)("."))(function () {
                return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Data_Array.some(Text_Parsing_Parser.alternativeParserT(dictMonad))(Text_Parsing_Parser.lazyParserT)(digit(dictMonad)))("fraction"))(function (digits) {
                    return Data_Maybe.maybe(Text_Parsing_Parser.fail(dictMonad)("not digit"))(Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad)))(Data_Foldable.foldr(Data_Foldable.foldableArray)(op)(new Data_Maybe.Just(0.0))(digits));
                });
            }));
        })();
        var escapeGap = Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(Data_Array.some(Text_Parsing_Parser.alternativeParserT(dictMonad))(Text_Parsing_Parser.lazyParserT)(space(dictMonad)))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)("\\")))("end of string gap");
        var escapeEmpty = Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)("&");
        var escMap = Data_Array.zip([ "a", "b", "f", "n", "r", "t", "v", "\\", "\"", "'" ])([ "\x07", "\x08", "\x0c", "\x0a", "\x0d", "\x09", "\x0b", "\\", "\"", "'" ]);
        var dot = symbol(".");
        var decimal = number(10)(digit(dictMonad));
        var exponent$prime = (function () {
            var power = function (e) {
                if (e < 0) {
                    return 1.0 / power(-e | 0);
                };
                if (Data_Boolean.otherwise) {
                    return $$Math.pow(10.0)(Data_Int.toNumber(e));
                };
                throw new Error("Failed pattern match at Text.Parsing.Parser.Token (line 563, column 9 - line 563, column 31): " + [ e.constructor.name ]);
            };
            return Text_Parsing_Parser_Combinators.asErrorMessage(dictMonad)("exponent")(Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Text_Parsing_Parser_String.oneOf(Text_Parsing_Parser_String.stringLikeString)(dictMonad)([ "e", "E" ]))(function () {
                return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(sign(Data_Ring.ringInt))(function (f) {
                    return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(decimal)("exponent"))(function (e) {
                        return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(power(f(e)));
                    });
                });
            }));
        })();
        var fractExponent = function (n) {
            var justExponent = Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(exponent$prime)(function (expo) {
                return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(Data_Int.toNumber(n) * expo);
            });
            var fractExponent$prime = Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(fraction)(function (fract) {
                return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Text_Parsing_Parser_Combinators.option(dictMonad)(1.0)(exponent$prime))(function (expo) {
                    return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))((Data_Int.toNumber(n) + fract) * expo);
                });
            });
            return Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(fractExponent$prime)(justExponent);
        };
        var fractFloat = function (n) {
            return Data_Functor.map(Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(Data_Either.Right.create)(fractExponent(n));
        };
        var decimalFloat = Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(decimal)(function (n) {
            return Text_Parsing_Parser_Combinators.option(dictMonad)(new Data_Either.Left(n))(fractFloat(n));
        });
        var zeroNumFloat = Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Data_Functor.map(Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(Data_Either.Left.create)(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(hexadecimal)(octal)))(decimalFloat))(fractFloat(0)))(Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(new Data_Either.Left(0)));
        var natFloat = Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)("0"))(zeroNumFloat))(decimalFloat);
        var naturalOrFloat = Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(lexeme(natFloat))("number");
        var floating = Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(decimal)(fractExponent);
        var $$float = Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(lexeme(floating))("float");
        var zeroNumber = Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)("0"))(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(hexadecimal)(octal))(decimal))(Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(0))))("");
        var nat = Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(zeroNumber)(decimal);
        var $$int = Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(lexeme(sign(Data_Ring.ringInt)))(function (f) {
            return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(nat)(function (n) {
                return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(f(n));
            });
        });
        var integer = Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(lexeme($$int))("integer");
        var natural = Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(lexeme(nat))("natural");
        var comma = symbol(",");
        var commaSep = function (p) {
            return Text_Parsing_Parser_Combinators.sepBy(dictMonad)(p)(comma);
        };
        var commaSep1 = function (p) {
            return Text_Parsing_Parser_Combinators.sepBy1(dictMonad)(p)(comma);
        };
        var colon = symbol(":");
        var charNum = Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(decimal)(Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)("o"))(number(8)(octDigit(dictMonad)))))(Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)("x"))(number(16)(hexDigit(dictMonad)))))(function (code) {
            var $88 = code > 1114111;
            if ($88) {
                return Text_Parsing_Parser.fail(dictMonad)("invalid escape sequence");
            };
            var v1 = Data_Char.fromCharCode(code);
            if (v1 instanceof Data_Maybe.Just) {
                return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(v1.value0);
            };
            if (v1 instanceof Data_Maybe.Nothing) {
                return Text_Parsing_Parser.fail(dictMonad)("invalid character code (should not happen)");
            };
            throw new Error("Failed pattern match at Text.Parsing.Parser.Token (line 448, column 17 - line 450, column 81): " + [ v1.constructor.name ]);
        });
        var charLetter = Text_Parsing_Parser_String.satisfy(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(function (c) {
            return c !== "'" && (c !== "\\" && c > "\x1a");
        });
        var charEsc = (function () {
            var parseEsc = function (v1) {
                return Data_Functor.voidLeft(Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)(v1.value0))(v1.value1);
            };
            return Text_Parsing_Parser_Combinators.choice(Data_Foldable.foldableArray)(dictMonad)(Data_Functor.map(Data_Functor.functorArray)(parseEsc)(escMap));
        })();
        var charControl = Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)("^"))(function () {
            return Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(upper(dictMonad))(function (code) {
                var v1 = Data_Char.fromCharCode((Data_Char.toCharCode(code) - Data_Char.toCharCode("A") | 0) + 1 | 0);
                if (v1 instanceof Data_Maybe.Just) {
                    return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(v1.value0);
                };
                if (v1 instanceof Data_Maybe.Nothing) {
                    return Text_Parsing_Parser.fail(dictMonad)("invalid character code (should not happen)");
                };
                throw new Error("Failed pattern match at Text.Parsing.Parser.Token (line 437, column 9 - line 439, column 73): " + [ v1.constructor.name ]);
            });
        });
        var caseString = function (name) {
            if (v.caseSensitive) {
                return Data_Functor.voidLeft(Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(Text_Parsing_Parser_String.string(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(name))(name);
            };
            if (Data_Boolean.otherwise) {
                var msg = Data_Show.show(Data_Show.showString)(name);
                var caseChar = function (c) {
                    if (Data_Char_Unicode.isAlpha(c)) {
                        return Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)(Data_Char_Unicode.toLower(c)))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)(Data_Char_Unicode.toUpper(c)));
                    };
                    if (Data_Boolean.otherwise) {
                        return Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)(c);
                    };
                    throw new Error("Failed pattern match at Text.Parsing.Parser.Token (line 658, column 9 - line 658, column 50): " + [ c.constructor.name ]);
                };
                var walk = function (name$prime) {
                    var v1 = Data_String_CodeUnits.uncons(name$prime);
                    if (v1 instanceof Data_Maybe.Nothing) {
                        return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(Data_Unit.unit);
                    };
                    if (v1 instanceof Data_Maybe.Just) {
                        return Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(caseChar(v1.value0.head))(msg))(walk(v1.value0.tail));
                    };
                    throw new Error("Failed pattern match at Text.Parsing.Parser.Token (line 654, column 22 - line 656, column 86): " + [ v1.constructor.name ]);
                };
                return Data_Functor.voidLeft(Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(walk(name))(name);
            };
            throw new Error("Failed pattern match at Text.Parsing.Parser.Token (line 649, column 5 - line 649, column 52): " + [ name.constructor.name ]);
        };
        var reserved = function (name) {
            var go = Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(caseString(name))(Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Text_Parsing_Parser_Combinators.notFollowedBy(dictMonad)(v.identLetter))("end of " + name));
            return lexeme(Text_Parsing_Parser_Combinators["try"](dictMonad)(go));
        };
        var brackets = function (p) {
            return Text_Parsing_Parser_Combinators.between(dictMonad)(symbol("["))(symbol("]"))(p);
        };
        var braces = function (p) {
            return Text_Parsing_Parser_Combinators.between(dictMonad)(symbol("{"))(symbol("}"))(p);
        };
        var ascii3codes = [ "NUL", "SOH", "STX", "ETX", "EOT", "ENQ", "ACK", "BEL", "DLE", "DC1", "DC2", "DC3", "DC4", "NAK", "SYN", "ETB", "CAN", "SUB", "ESC", "DEL" ];
        var ascii3 = [ "\x00", "\x01", "\x02", "\x03", "\x04", "\x05", "\x06", "\x07", "\x10", "\x11", "\x12", "\x13", "\x14", "\x15", "\x16", "\x17", "\x18", "\x1a", "\x1b", "\x7f" ];
        var ascii2codes = [ "BS", "HT", "LF", "VT", "FF", "CR", "SO", "SI", "EM", "FS", "GS", "RS", "US", "SP" ];
        var ascii2 = [ "\x08", "\x09", "\x0a", "\x0b", "\x0c", "\x0d", "\x0e", "\x0f", "\x19", "\x1c", "\x1d", "\x1e", "\x1f", " " ];
        var asciiMap = Data_Array.zip(Data_Semigroup.append(Data_Semigroup.semigroupArray)(ascii3codes)(ascii2codes))(Data_Semigroup.append(Data_Semigroup.semigroupArray)(ascii3)(ascii2));
        var charAscii = (function () {
            var parseAscii = function (v1) {
                return Text_Parsing_Parser_Combinators["try"](dictMonad)(Data_Functor.voidLeft(Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(Text_Parsing_Parser_String.string(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(v1.value0))(v1.value1));
            };
            return Text_Parsing_Parser_Combinators.choice(Data_Foldable.foldableArray)(dictMonad)(Data_Functor.map(Data_Functor.functorArray)(parseAscii)(asciiMap));
        })();
        var escapeCode = Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(charEsc)(charNum))(charAscii))(charControl))("escape code");
        var charEscape = Control_Apply.applySecond(Text_Parsing_Parser.applyParserT(dictMonad))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)("\\"))(escapeCode);
        var characterChar = Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(charLetter)(charEscape))("literal character");
        var charLiteral = (function () {
            var go = Text_Parsing_Parser_Combinators.between(dictMonad)(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)("'"))(Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)("'"))("end of character"))(characterChar);
            return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(lexeme(go))("character");
        })();
        var stringEscape = Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)("\\"))(function () {
            return Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Data_Functor.voidLeft(Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(escapeGap)(Data_Maybe.Nothing.value))(Data_Functor.voidLeft(Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(escapeEmpty)(Data_Maybe.Nothing.value)))(Data_Functor.map(Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(Data_Maybe.Just.create)(escapeCode));
        });
        var stringChar = Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Control_Alt.alt(Text_Parsing_Parser.altParserT(dictMonad))(Data_Functor.map(Text_Parsing_Parser.functorParserT(((dictMonad.Bind1()).Apply0()).Functor0()))(Data_Maybe.Just.create)(stringLetter))(stringEscape))("string character");
        var stringLiteral = (function () {
            var folder = function (v1) {
                return function (chars) {
                    if (v1 instanceof Data_Maybe.Nothing) {
                        return chars;
                    };
                    if (v1 instanceof Data_Maybe.Just) {
                        return new Data_List_Types.Cons(v1.value0, chars);
                    };
                    throw new Error("Failed pattern match at Text.Parsing.Parser.Token (line 404, column 9 - line 404, column 55): " + [ v1.constructor.name, chars.constructor.name ]);
                };
            };
            var go = Control_Bind.bind(Text_Parsing_Parser.bindParserT(dictMonad))(Text_Parsing_Parser_Combinators.between(dictMonad)(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)("\""))(Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(dictMonad)("\""))("end of string"))(Data_List.many(Text_Parsing_Parser.alternativeParserT(dictMonad))(Text_Parsing_Parser.lazyParserT)(stringChar)))(function (maybeChars) {
                return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(dictMonad))(Data_String_CodeUnits.fromCharArray(Data_List.toUnfoldable(Data_Unfoldable.unfoldableArray)(Data_Foldable.foldr(Data_List_Types.foldableList)(folder)(Data_List_Types.Nil.value)(maybeChars))));
            });
            return lexeme(Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(go)("literal string"));
        })();
        var angles = function (p) {
            return Text_Parsing_Parser_Combinators.between(dictMonad)(symbol("<"))(symbol(">"))(p);
        };
        return {
            identifier: identifier,
            reserved: reserved,
            operator: operator,
            reservedOp: reservedOp,
            charLiteral: charLiteral,
            stringLiteral: stringLiteral,
            natural: natural,
            integer: integer,
            "float": $$float,
            naturalOrFloat: naturalOrFloat,
            decimal: decimal,
            hexadecimal: hexadecimal,
            octal: octal,
            symbol: symbol,
            lexeme: lexeme,
            whiteSpace: whiteSpace$prime(dictMonad)(v),
            parens: parens,
            braces: braces,
            angles: angles,
            brackets: brackets,
            semi: semi,
            comma: comma,
            colon: colon,
            dot: dot,
            semiSep: semiSep,
            semiSep1: semiSep1,
            commaSep: commaSep,
            commaSep1: commaSep1
        };
    };
};
var alphaNum = function (dictMonad) {
    return Text_Parsing_Parser_Combinators.withErrorMessage(dictMonad)(Text_Parsing_Parser_String.satisfy(Text_Parsing_Parser_String.stringLikeString)(dictMonad)(Data_Char_Unicode.isAlphaNum))("letter or digit");
};
module.exports = {
    token: token,
    when: when,
    match: match,
    LanguageDef: LanguageDef,
    unGenLanguageDef: unGenLanguageDef,
    makeTokenParser: makeTokenParser,
    digit: digit,
    hexDigit: hexDigit,
    octDigit: octDigit,
    upper: upper,
    space: space,
    letter: letter,
    alphaNum: alphaNum
};
