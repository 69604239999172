"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Data_Array = require("../Data.Array/index.js");
var Data_Either = require("../Data.Either/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Identity = require("../Data.Identity/index.js");
var Data_List = require("../Data.List/index.js");
var Data_Maybe = require("../Data.Maybe/index.js");
var Data_Number = require("../Data.Number/index.js");
var Data_Show = require("../Data.Show/index.js");
var Data_String_CodeUnits = require("../Data.String.CodeUnits/index.js");
var Data_Unfoldable = require("../Data.Unfoldable/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Text_Parsing_Parser = require("../Text.Parsing.Parser/index.js");
var Text_Parsing_Parser_String = require("../Text.Parsing.Parser.String/index.js");
var Text_Parsing_Parser_Token = require("../Text.Parsing.Parser.Token/index.js");
var unwrapEither = function (v) {
    if (v instanceof Data_Either.Left) {
        return {
            left: [ v.value0 ],
            right: [  ]
        };
    };
    if (v instanceof Data_Either.Right) {
        return {
            left: [  ],
            right: [ v.value0 ]
        };
    };
    throw new Error("Failed pattern match at VTT (line 87, column 1 - line 87, column 62): " + [ v.constructor.name ]);
};
var time = (function () {
    var toNum = function (s) {
        return Data_Maybe.fromJust()(Data_Number.fromString(Data_String_CodeUnits.fromCharArray(s)));
    };
    return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Data_Functor.map(Text_Parsing_Parser.functorParserT(Data_Identity.functorIdentity))(toNum)(Data_Array.some(Text_Parsing_Parser.alternativeParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser.lazyParserT)(Text_Parsing_Parser_Token.digit(Data_Identity.monadIdentity))))(function (minutes) {
        return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)(":"))(function () {
            return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Data_Functor.map(Text_Parsing_Parser.functorParserT(Data_Identity.functorIdentity))(toNum)(Data_Array.some(Text_Parsing_Parser.alternativeParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser.lazyParserT)(Text_Parsing_Parser_Token.digit(Data_Identity.monadIdentity))))(function (seconds) {
                return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser_String["char"](Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)("."))(function () {
                    return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Data_Functor.map(Text_Parsing_Parser.functorParserT(Data_Identity.functorIdentity))(toNum)(Data_Array.some(Text_Parsing_Parser.alternativeParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser.lazyParserT)(Text_Parsing_Parser_Token.digit(Data_Identity.monadIdentity))))(function (milliseconds) {
                        return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(Data_Identity.monadIdentity))((minutes * 60.0 + seconds) * 1000.0 + milliseconds);
                    });
                });
            });
        });
    });
})();
var listToStr = (function () {
    var $10 = Data_List.toUnfoldable(Data_Unfoldable.unfoldableArray);
    return function ($11) {
        return Data_String_CodeUnits.fromCharArray($10($11));
    };
})();
var line = Data_Functor.map(Text_Parsing_Parser.functorParserT(Data_Identity.functorIdentity))(Data_String_CodeUnits.fromCharArray)(Data_Array.many(Text_Parsing_Parser.alternativeParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser.lazyParserT)(Text_Parsing_Parser_String.noneOf(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)([ "\x0a" ])));
var vttLine = Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser_String.skipSpaces(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity))(function () {
    return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(time)(function (fromTime_) {
        return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser_String.skipSpaces(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity))(function () {
            return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser_String.string(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)("-->"))(function () {
                return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser_String.skipSpaces(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity))(function () {
                    return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(time)(function (toTime_) {
                        return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser_String.skipSpaces(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity))(function () {
                            return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(line)(function (message_) {
                                return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser_String.skipSpaces(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity))(function () {
                                    return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(Data_Identity.monadIdentity))({
                                        fromTime: fromTime_,
                                        toTime: toTime_,
                                        message: message_
                                    });
                                });
                            });
                        });
                    });
                });
            });
        });
    });
});
var hi = "Hi";
var firstLine = Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser_String.string(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity)("WEBVTT"))(function (v) {
    return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(Data_Identity.monadIdentity))(Data_Unit.unit);
});
var vtt = Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser_String.skipSpaces(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity))(function () {
    return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(firstLine)(function () {
        return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser_String.skipSpaces(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity))(function () {
            return Control_Bind.bind(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Data_Array.many(Text_Parsing_Parser.alternativeParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser.lazyParserT)(vttLine))(function (lines_) {
                return Control_Bind.discard(Control_Bind.discardUnit)(Text_Parsing_Parser.bindParserT(Data_Identity.monadIdentity))(Text_Parsing_Parser_String.eof(Text_Parsing_Parser_String.stringLikeString)(Data_Identity.monadIdentity))(function () {
                    return Control_Applicative.pure(Text_Parsing_Parser.applicativeParserT(Data_Identity.monadIdentity))({
                        lines: lines_
                    });
                });
            });
        });
    });
});
var parse = function (input) {
    var v = Text_Parsing_Parser.runParser(input)(vtt);
    if (v instanceof Data_Either.Right) {
        return new Data_Either.Right(v.value0);
    };
    if (v instanceof Data_Either.Left) {
        return Data_Either.Left.create(Data_Show.show(Text_Parsing_Parser.showParseError)(v.value0));
    };
    throw new Error("Failed pattern match at VTT (line 80, column 15 - line 82, column 30): " + [ v.constructor.name ]);
};
var example = "WEBVTT\x0a\x0a00:14.848 --> 00:16.725\x0a\uc790\uc720\uc2dc\uac04 \ub9ce\uc774 \ub4dc\ub838\uc2b5\ub2c8\ub2e4.";
module.exports = {
    firstLine: firstLine,
    vttLine: vttLine,
    time: time,
    listToStr: listToStr,
    line: line,
    example: example,
    vtt: vtt,
    parse: parse,
    unwrapEither: unwrapEither,
    hi: hi
};
