// Generated by purs version 0.13.8
"use strict";
var RLProxy = (function () {
  function RLProxy() {}
  RLProxy.value = new RLProxy();
  return RLProxy;
})();
module.exports = {
  RLProxy: RLProxy,
};
