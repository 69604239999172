// Generated by purs version 0.13.8
"use strict";
var Control_Apply = require("../Control.Apply/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Applicative = function (Apply0, pure) {
  this.Apply0 = Apply0;
  this.pure = pure;
};
var pure = function (dict) {
  return dict.pure;
};
var unless = function (dictApplicative) {
  return function (v) {
    return function (v1) {
      if (!v) {
        return v1;
      }
      if (v) {
        return pure(dictApplicative)(Data_Unit.unit);
      }
      throw new Error(
        "Failed pattern match at Control.Applicative (line 62, column 1 - line 62, column 65): " +
          [v.constructor.name, v1.constructor.name],
      );
    };
  };
};
var when = function (dictApplicative) {
  return function (v) {
    return function (v1) {
      if (v) {
        return v1;
      }
      if (!v) {
        return pure(dictApplicative)(Data_Unit.unit);
      }
      throw new Error(
        "Failed pattern match at Control.Applicative (line 57, column 1 - line 57, column 63): " +
          [v.constructor.name, v1.constructor.name],
      );
    };
  };
};
var liftA1 = function (dictApplicative) {
  return function (f) {
    return function (a) {
      return Control_Apply.apply(dictApplicative.Apply0())(
        pure(dictApplicative)(f),
      )(a);
    };
  };
};
var applicativeFn = new Applicative(
  function () {
    return Control_Apply.applyFn;
  },
  function (x) {
    return function (v) {
      return x;
    };
  },
);
var applicativeArray = new Applicative(
  function () {
    return Control_Apply.applyArray;
  },
  function (x) {
    return [x];
  },
);
module.exports = {
  Applicative: Applicative,
  pure: pure,
  liftA1: liftA1,
  unless: unless,
  when: when,
  applicativeFn: applicativeFn,
  applicativeArray: applicativeArray,
};
