// Generated by purs version 0.13.8
"use strict";
var $foreign = require("./foreign.js");
var Data_Symbol = require("../Data.Symbol/index.js");
var Data_Unit = require("../Data.Unit/index.js");
var Data_Void = require("../Data.Void/index.js");
var Record_Unsafe = require("../Record.Unsafe/index.js");
var Type_Data_RowList = require("../Type.Data.RowList/index.js");
var SemigroupRecord = function (appendRecord) {
  this.appendRecord = appendRecord;
};
var Semigroup = function (append) {
  this.append = append;
};
var semigroupVoid = new Semigroup(function (v) {
  return Data_Void.absurd;
});
var semigroupUnit = new Semigroup(function (v) {
  return function (v1) {
    return Data_Unit.unit;
  };
});
var semigroupString = new Semigroup($foreign.concatString);
var semigroupRecordNil = new SemigroupRecord(function (v) {
  return function (v1) {
    return function (v2) {
      return {};
    };
  };
});
var semigroupArray = new Semigroup($foreign.concatArray);
var appendRecord = function (dict) {
  return dict.appendRecord;
};
var semigroupRecord = function (dictRowToList) {
  return function (dictSemigroupRecord) {
    return new Semigroup(
      appendRecord(dictSemigroupRecord)(Type_Data_RowList.RLProxy.value),
    );
  };
};
var append = function (dict) {
  return dict.append;
};
var semigroupFn = function (dictSemigroup) {
  return new Semigroup(function (f) {
    return function (g) {
      return function (x) {
        return append(dictSemigroup)(f(x))(g(x));
      };
    };
  });
};
var semigroupRecordCons = function (dictIsSymbol) {
  return function (dictCons) {
    return function (dictSemigroupRecord) {
      return function (dictSemigroup) {
        return new SemigroupRecord(function (v) {
          return function (ra) {
            return function (rb) {
              var tail = appendRecord(dictSemigroupRecord)(
                Type_Data_RowList.RLProxy.value,
              )(ra)(rb);
              var key = Data_Symbol.reflectSymbol(dictIsSymbol)(
                Data_Symbol.SProxy.value,
              );
              var insert = Record_Unsafe.unsafeSet(key);
              var get = Record_Unsafe.unsafeGet(key);
              return insert(append(dictSemigroup)(get(ra))(get(rb)))(tail);
            };
          };
        });
      };
    };
  };
};
module.exports = {
  Semigroup: Semigroup,
  append: append,
  SemigroupRecord: SemigroupRecord,
  appendRecord: appendRecord,
  semigroupString: semigroupString,
  semigroupUnit: semigroupUnit,
  semigroupVoid: semigroupVoid,
  semigroupFn: semigroupFn,
  semigroupArray: semigroupArray,
  semigroupRecord: semigroupRecord,
  semigroupRecordNil: semigroupRecordNil,
  semigroupRecordCons: semigroupRecordCons,
};
