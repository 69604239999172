// Generated by purs version 0.13.8
"use strict";
var $foreign = require("./foreign.js");
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad = require("../Control.Monad/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var monadEffect = new Control_Monad.Monad(
  function () {
    return applicativeEffect;
  },
  function () {
    return bindEffect;
  },
);
var bindEffect = new Control_Bind.Bind(function () {
  return applyEffect;
}, $foreign.bindE);
var applyEffect = new Control_Apply.Apply(function () {
  return functorEffect;
}, Control_Monad.ap(monadEffect));
var applicativeEffect = new Control_Applicative.Applicative(function () {
  return applyEffect;
}, $foreign.pureE);
var functorEffect = new Data_Functor.Functor(
  Control_Applicative.liftA1(applicativeEffect),
);
var semigroupEffect = function (dictSemigroup) {
  return new Data_Semigroup.Semigroup(
    Control_Apply.lift2(applyEffect)(Data_Semigroup.append(dictSemigroup)),
  );
};
var monoidEffect = function (dictMonoid) {
  return new Data_Monoid.Monoid(function () {
    return semigroupEffect(dictMonoid.Semigroup0());
  }, $foreign.pureE(Data_Monoid.mempty(dictMonoid)));
};
module.exports = {
  functorEffect: functorEffect,
  applyEffect: applyEffect,
  applicativeEffect: applicativeEffect,
  bindEffect: bindEffect,
  monadEffect: monadEffect,
  semigroupEffect: semigroupEffect,
  monoidEffect: monoidEffect,
  untilE: $foreign.untilE,
  whileE: $foreign.whileE,
  forE: $foreign.forE,
  foreachE: $foreign.foreachE,
};
