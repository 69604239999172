// Generated by purs version 0.13.8
"use strict";
var Control_Category = require("../Control.Category/index.js");
var Effect = require("../Effect/index.js");
var MonadEffect = function (Monad0, liftEffect) {
  this.Monad0 = Monad0;
  this.liftEffect = liftEffect;
};
var monadEffectEffect = new MonadEffect(function () {
  return Effect.monadEffect;
}, Control_Category.identity(Control_Category.categoryFn));
var liftEffect = function (dict) {
  return dict.liftEffect;
};
module.exports = {
  liftEffect: liftEffect,
  MonadEffect: MonadEffect,
  monadEffectEffect: monadEffectEffect,
};
