// Generated by purs version 0.13.8
"use strict";
var Control_Applicative = require("../Control.Applicative/index.js");
var Control_Apply = require("../Control.Apply/index.js");
var Control_Bind = require("../Control.Bind/index.js");
var Control_Monad = require("../Control.Monad/index.js");
var Data_Eq = require("../Data.Eq/index.js");
var Data_Functor = require("../Data.Functor/index.js");
var Data_HeytingAlgebra = require("../Data.HeytingAlgebra/index.js");
var Data_Monoid = require("../Data.Monoid/index.js");
var Data_Ord = require("../Data.Ord/index.js");
var Data_Semigroup = require("../Data.Semigroup/index.js");
var Data_Semiring = require("../Data.Semiring/index.js");
var Data_Show = require("../Data.Show/index.js");
var Disj = function (x) {
  return x;
};
var showDisj = function (dictShow) {
  return new Data_Show.Show(function (v) {
    return "(Disj " + (Data_Show.show(dictShow)(v) + ")");
  });
};
var semiringDisj = function (dictHeytingAlgebra) {
  return new Data_Semiring.Semiring(
    function (v) {
      return function (v1) {
        return Data_HeytingAlgebra.disj(dictHeytingAlgebra)(v)(v1);
      };
    },
    function (v) {
      return function (v1) {
        return Data_HeytingAlgebra.conj(dictHeytingAlgebra)(v)(v1);
      };
    },
    Data_HeytingAlgebra.tt(dictHeytingAlgebra),
    Data_HeytingAlgebra.ff(dictHeytingAlgebra),
  );
};
var semigroupDisj = function (dictHeytingAlgebra) {
  return new Data_Semigroup.Semigroup(function (v) {
    return function (v1) {
      return Data_HeytingAlgebra.disj(dictHeytingAlgebra)(v)(v1);
    };
  });
};
var ordDisj = function (dictOrd) {
  return dictOrd;
};
var monoidDisj = function (dictHeytingAlgebra) {
  return new Data_Monoid.Monoid(function () {
    return semigroupDisj(dictHeytingAlgebra);
  }, Data_HeytingAlgebra.ff(dictHeytingAlgebra));
};
var functorDisj = new Data_Functor.Functor(function (f) {
  return function (m) {
    return f(m);
  };
});
var eqDisj = function (dictEq) {
  return dictEq;
};
var eq1Disj = new Data_Eq.Eq1(function (dictEq) {
  return Data_Eq.eq(eqDisj(dictEq));
});
var ord1Disj = new Data_Ord.Ord1(
  function () {
    return eq1Disj;
  },
  function (dictOrd) {
    return Data_Ord.compare(ordDisj(dictOrd));
  },
);
var boundedDisj = function (dictBounded) {
  return dictBounded;
};
var applyDisj = new Control_Apply.Apply(
  function () {
    return functorDisj;
  },
  function (v) {
    return function (v1) {
      return v(v1);
    };
  },
);
var bindDisj = new Control_Bind.Bind(
  function () {
    return applyDisj;
  },
  function (v) {
    return function (f) {
      return f(v);
    };
  },
);
var applicativeDisj = new Control_Applicative.Applicative(function () {
  return applyDisj;
}, Disj);
var monadDisj = new Control_Monad.Monad(
  function () {
    return applicativeDisj;
  },
  function () {
    return bindDisj;
  },
);
module.exports = {
  Disj: Disj,
  eqDisj: eqDisj,
  eq1Disj: eq1Disj,
  ordDisj: ordDisj,
  ord1Disj: ord1Disj,
  boundedDisj: boundedDisj,
  showDisj: showDisj,
  functorDisj: functorDisj,
  applyDisj: applyDisj,
  applicativeDisj: applicativeDisj,
  bindDisj: bindDisj,
  monadDisj: monadDisj,
  semigroupDisj: semigroupDisj,
  monoidDisj: monoidDisj,
  semiringDisj: semiringDisj,
};
