// Generated by purs version 0.13.8
"use strict";
var Semigroupoid = function (compose) {
  this.compose = compose;
};
var semigroupoidFn = new Semigroupoid(function (f) {
  return function (g) {
    return function (x) {
      return f(g(x));
    };
  };
});
var compose = function (dict) {
  return dict.compose;
};
var composeFlipped = function (dictSemigroupoid) {
  return function (f) {
    return function (g) {
      return compose(dictSemigroupoid)(g)(f);
    };
  };
};
module.exports = {
  compose: compose,
  Semigroupoid: Semigroupoid,
  composeFlipped: composeFlipped,
  semigroupoidFn: semigroupoidFn,
};
